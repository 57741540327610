// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 
                     "myBtn",
                     "myListBtn",
                     "myMapBtn",
                     "myModal", 
                     "listView", 
                     "mapView",
                     "mainPage",
                     "subPage",
                     "searchInput",
                     "lat",
                     "lng"
                   ]

  connect() {
    this.initializeModal()
    this.subPageTarget.style.display = "none"
  }

  initializeModal() {
    this.myBtnTarget.addEventListener("click", ()=> {
      this.myModalTarget.style.display = "block";
    });
  }

  search() {
    this.myModalTarget.style.display = "block";
  }

  hide_search() {
    this.myModalTarget.style.display = "none";
  }

  ////home page togle list/map

    list_view() {

      const mainPageState = this.mainPageTarget.style.display
      const myListBtnState = this.myListBtnTarget.style 

      if (mainPageState !== "block") {
        this.mainPageTarget.style.display = "block";
        this.subPageTarget.style.display = "none";
        this.myListBtnTarget.classList.add("list__view-active");
        this.myMapBtnTarget.classList.remove("map__view-active");
      } 
    }

    map_view() {

      const subPageState = this.subPageTarget.style.display

      if (subPageState !== "block") {
        this.mainPageTarget.style.display = "none";
        this.subPageTarget.style.display = "block";
        this.myListBtnTarget.classList.remove("list__view-active");
        this.myMapBtnTarget.classList.add("map__view-active");
      }
    }



  connect() {
    this.initGoogle();
  }

  disconnect() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }
  }

  initGoogle() {
    if (typeof google !== 'undefined' && google.maps && google.maps.places) {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, {
        fields: ["address_components", "geometry"],
        types: ["address"],
        componentRestrictions: { country: "za"},
      });

      this.autocomplete.addListener('place_changed', this.placeSelected.bind(this));
    }
  }
  placeSelected() {

    const place = this.autocomplete.getPlace();

    if (!place.geometry){
      return;
    }

    this.latTarget.value = place.geometry.location.lat();
    this.lngTarget.value = place.geometry.location.lng();
  }
}
